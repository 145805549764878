

import {Component, Mixins, Prop} from "vue-property-decorator";
import {IMaasHacziParam} from "@/plugins/uyap-plugin/uyap/GetMaasHacziParams";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class HacizTalepMaasHacziCalismaDurumuPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<IMaasHacziParam> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  created() {
    this.load();
  }

  onDurumChange(v: IMaasHacziParam) {
    this.$emit('calismaDurumuText', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  async load() {
    this.items = (await this.$uyap.GetMaasHacziParams().run({}));
  }
}
