
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {YuzuncuMaddeyeYararBilgiIstenilmesiTalep} from "@/interface/IUyapTalep";
import AvukatTalepMalTuruRadio from "@/components/radio/uyap/AvukatTalepMalTuruRadio.vue";

@Component({
  components: {AvukatTalepMalTuruRadio}
})
export default class YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: YuzuncuMaddeyeYararBilgiIstenilmesiTalep;
}
