
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})
export default class Plaka extends Mixins(SimpleInputMixin) {
  @Prop() label!: string;
  @Prop() rules!: string;

  get rule() {
    if (this.localValue) {
      if (this.localValue.match(/^(0[1-9]|[1-7][0-9]|8[01])((\s?[a-zA-Z]\s?)(\d{4,5})|(\s?[a-zA-Z]{2}\s?)(\d{3,4})|(\s?[a-zA-Z]{3}\s?)(\d{2,3}))$/) !== null) {
        if (this.rules)
          return this.rules;
        else
          return [true]
      }
      else
        return ['Yanlış plaka bilgisi.']
    } else {
      if (this.rules)
        return this.rules
      else
        return [true]
    }
  }
}
