
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TemlikBilgisininBildirilmesiTalep} from "@/interface/IUyapTalep";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import UyapParaBirimiPicker from "@/components/pickers/uyap/UyapParaBirimiPicker.vue";
import UyapAlacakliPicker from "@/components/pickers/uyap/UyapAlacakliPicker.vue";
import VarsayilanAvukatTalepAdresTuruPicker from "@/components/pickers/uyap/VarsayilanAvukatTalepAdresTuruPicker.vue";

@Component({
  components: {
    VarsayilanAvukatTalepAdresTuruPicker,
    UyapAlacakliPicker,
    UyapParaBirimiPicker, Tutar, AvukatTalepAdresTuruPicker, AvukatTalepTebligatTuruPicker}
})
export default class TemlikBilgisininBildirilmesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: string;
  @Prop() tckn!: string;
  @Prop() vergiNo!: string;
  @Prop() kisiMi!: boolean;
  @Prop() varsayilan!: boolean|string;

  localValue!: TemlikBilgisininBildirilmesiTalep;
  sorguProgress:boolean=false;

  get isVarsayilan(){
    return this.varsayilan || this.varsayilan === "";
  }

  onKismiTemlikChange() {
    if (this.localValue.kismiTemlikMi) {
      this.localValue.kismiTemlikMiStr = 'Evet';
      this.localValue.temlikTipi = 0;
    } else {
      this.localValue.kismiTemlikMiStr = 'Hayır';
      this.localValue.temlikTipi = 1;
    }
    this.input();
  }

  async adresSorgula() {
    this.sorguProgress = true;

    // mernis 1 mersis 2
    if (this.localValue.adresTuru == '1' && this.tckn) {
      let mernisRes = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: this.tckn});
      this.localValue.adres = mernisRes ? 'VAR' : 'YOK';
    } else if (this.localValue.adresTuru == '2' && this.vergiNo) {
      let mersisRes = await this.$uyap.GetKurumMersisAdresi().run({vergiNo: this.vergiNo,mersisNo:''});
      this.localValue.adres = !!mersisRes ? 'VAR' : 'YOK';
    }
    setTimeout(() => {
      this.sorguProgress=false
    }, 2000)
  }
}
