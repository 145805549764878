

import {Component, Mixins, Prop} from "vue-property-decorator";
import {PortalAvukatTalepTebligatTuruListCevapRow} from "@/plugins/uyap-plugin/uyap/PortalAvukatTalepTebligatTuruList";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class AvukatTalepTebligatTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<PortalAvukatTalepTebligatTuruListCevapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onTebligatTuruChange(v: PortalAvukatTalepTebligatTuruListCevapRow) {
    this.$emit('tebligatTuruAciklama', (this.items.find((item) => (item.postaMasraflariId == this.localValue)))?.masrafAdi);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.PortalAvukatTalepTebligatTuruList().run({}));
  }
}
