
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class NewFormDialog extends Vue {
  @Prop({default: "600"}) width!: string | number;
  @Prop() title!: string;
  @Prop() tooltip!: string | HTMLElement;
  /*
    Button Props: dark, outlined, color, disabled, button-width, button-height, font-color, icon-button, button-style, x-large/large/small/x-small
    Button Type: link(a tag) / undefined(button)
    Icon Props: icon, x-large/large/small/x-small
  */

  show: boolean = false;

  onClose() {
    this.show = false;
  }

  onSuccess(data: any) {
    this.onClose();
    this.$emit("success", data);
  }
}
