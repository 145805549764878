
import {Component, Mixins, Prop} from "vue-property-decorator";
import {IAvukatTalepAdresTuru} from "@/plugins/uyap-plugin/uyap/GetPortalAvukatTalepAdresTuruList";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class VarsayilanAvukatTalepAdresTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  items: Array<any> = [{"name":"E-Tebligat Adresi","value":0},{"name":"Mernis/Mersis Adresi","value":1},{"name":"Yeni Adres","value":3}];

  onAdresTuruChange(v: IAvukatTalepAdresTuru) {
    this.$emit('adresTuruAciklama', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }
}
