
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalep} from "@/interface/IUyapTalep";
import Dates from "@/components/inputs/Dates.vue";

@Component({
  components: {Dates}
})
export default class MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalep;
}
