
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {DosyaninIslemdenKaldirilmasiTalep} from "@/interface/IUyapTalep";
import DosyaKapatmaNedenleriPicker from "@/components/pickers/uyap/DosyaKapatmaNedenleriPicker.vue";

@Component({
  components: {DosyaKapatmaNedenleriPicker}
})
export default class DosyaninIslemdenKaldirilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: DosyaninIslemdenKaldirilmasiTalep;
}
