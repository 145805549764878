
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {IIK150CSerhiEklenmesiTalep} from "@/interface/IUyapTalep";

@Component({
  components: {}
})
export default class Iik150cSerhiEklenmesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: IIK150CSerhiEklenmesiTalep;
}
