

import {Component, Mixins, Prop} from "vue-property-decorator";
import {ITarafBilgisiDetayliTarafRollu} from "@/plugins/uyap-plugin/uyap/GetDosyaTarafListesiWithTarafRol";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TarafRol} from "@/plugins/uyap-plugin/enum/uyap/TarafRol";

@Component({
  components: {}
})

export default class UyapAlacakliPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: string;
  @Prop() rules!: string;
  @Prop() label!: string;

  items: Array<ITarafBilgisiDetayliTarafRollu> = [];

  onAlacakliChange(v: ITarafBilgisiDetayliTarafRollu) {
    this.$emit('temlikAlacakliStr', (this.items.find(item => item.guncelKisiKurumId == this.localValue))?.tarafAdi);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.GetDosyaTarafListesiWithTarafRol().run({
      dosyaId: this.dosyaId,
      islemTuru: TarafRol.ALACAKLI
    }));
  }
}
