
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {YenilemeEmrininTebligeCikartilmasiTalep} from "@/interface/IUyapTalep";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";
import VarsayilanAvukatTalepAdresTuruPicker from "@/components/pickers/uyap/VarsayilanAvukatTalepAdresTuruPicker.vue";

@Component({
  components: {VarsayilanAvukatTalepAdresTuruPicker, AvukatTalepAdresTuruPicker, AvukatTalepTebligatTuruPicker}
})
export default class YenilemeEmrininTebligeCikartilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() tckn!: string;
  @Prop() vergiNo!: string;
  @Prop() kisiMi!: boolean;
  @Prop() varsayilan!: boolean|string;

  sorguProgress:boolean=false;
  localValue!: YenilemeEmrininTebligeCikartilmasiTalep;

  get isVarsayilan(){
    return this.varsayilan || this.varsayilan === "";
  }

  async adresSorgula() {
    this.sorguProgress = true;
    // mernis 1 mersis 2
    if (this.localValue.adresTuru == '1' && this.tckn) {
      let mernisRes = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: this.tckn});
      this.localValue.adres = mernisRes ? 'VAR' : 'YOK';
    } else if (this.localValue.adresTuru == '2' && this.vergiNo) {
      let mersisRes = await this.$uyap.GetKurumMersisAdresi().run({vergiNo: this.vergiNo,mersisNo:''});
      this.localValue.adres = !!mersisRes ? 'VAR' : 'YOK';
    }
    setTimeout(() => {
      this.sorguProgress=false
    }, 2000)
  }
}
