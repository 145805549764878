

import {Component, Mixins, Prop} from "vue-property-decorator";
import {ParaBirimleriCevapRow} from "@/plugins/uyap-plugin/uyap/ParaBirimleri";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class UyapParaBirimiPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules !: string;
  items: Array<ParaBirimleriCevapRow> = [];

  onParaBirimiChange(v: ParaBirimleriCevapRow){
    this.$emit('temlikTutarTuruStr', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.ParaBirimleri().run({})).items;
  }
}
