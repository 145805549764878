
import {Component, Mixins, Prop} from "vue-property-decorator";
import {PortalAvukatTalepMalTuruListCevapRow} from "@/plugins/uyap-plugin/uyap/PortalAvukatTalepMalTuruList";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class AvukatTalepMalTuruRadio extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<PortalAvukatTalepMalTuruListCevapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onMalTuruChange(v: PortalAvukatTalepMalTuruListCevapRow) {
    this.$emit('malTipAciklama', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = await this.$uyap.PortalAvukatTalepMalTuruList().run({});
  }
}
