
import {Vue, Component, Mixins, Prop} from 'vue-property-decorator';
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import KiymetTakdirininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/KiymetTakdirininTebligeCikartilmasiTalepEvrakForm.vue";
import YenilemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YenilemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm.vue";
import TemlikBilgisininBildirilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TemlikBilgisininBildirilmesiTalepEvrakForm.vue";
import YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm.vue";
import TaahhuduKabulMuhtirasiTebligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TaahhuduKabulMuhtirasiTebligiTalepEvrakForm.vue";
import {UyapTebligatEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapTebligatEvrakTuru";

@Component({
  computed: {
    TebligatEvrakTuru() {
      return UyapTebligatEvrakTuru;
    }
  },
  components: {
    TaahhuduKabulMuhtirasiTebligiTalepEvrakForm,
    YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm,
    TemlikBilgisininBildirilmesiTalepEvrakForm,
    BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm,
    IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm,
    YenilemeEmrininTebligeCikartilmasiTalepEvrakForm,
    KiymetTakdirininTebligeCikartilmasiTalepEvrakForm,
    NewFormWrapper}
})
export default class TopluTebligatDuzenleForm extends Mixins(ObjectInputMixin) {
  @Prop() talepler!: Array<AvukatTalepListCevapRow>;
  @Prop() dosyaId!: number|string;
  // takip.task.response.dosya.dosyaId
  showTalep(talepKod: UyapTebligatEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }
}
