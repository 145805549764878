
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {DosyaninYetkiliIcraDairesineGonderilmesiTalep} from "@/interface/IUyapTalep";
import UyapAdliyePicker from "@/components/pickers/uyap/UyapAdliyePicker.vue";

@Component({
  components: {UyapAdliyePicker}
})
export default class DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: DosyaninYetkiliIcraDairesineGonderilmesiTalep;
}
