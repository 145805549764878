
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AcizBelgesiHazirlanmasiTalep} from "@/interface/IUyapTalep";

@Component({
  components:{}
})
export default class AcizBelgesiHazirlanmasiTalepEvrakForm extends Mixins(ObjectInputMixin){
  localValue!: AcizBelgesiHazirlanmasiTalep;
}
