
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import { MaasUzerindekiHacizlerinKaldirilmasiTalep} from "@/interface/IUyapTalep";
import HacizTalepMaasHacziCalismaDurumuPicker
  from "@/components/pickers/uyap/HacizTalepMaasHacziCalismaDurumuPicker.vue";

@Component({
  components: {HacizTalepMaasHacziCalismaDurumuPicker}
})
export default class MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: MaasUzerindekiHacizlerinKaldirilmasiTalep;
}
