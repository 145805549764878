
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalep} from "@/interface/IUyapTalep";
import Dates from "@/components/inputs/Dates.vue";

@Component({
  components: {Dates}
})
export default class HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalep;
}
