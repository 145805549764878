
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {DosyadaHacizliMalinSatisiTalep} from "@/interface/IUyapTalep";
import AvukatTalepMalTuruRadio from "@/components/radio/uyap/AvukatTalepMalTuruRadio.vue";
import Dates from "@/components/inputs/Dates.vue";

@Component({
  components: {Dates, AvukatTalepMalTuruRadio}
})
export default class DosyadaHacizliMalinSatisiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: DosyadaHacizliMalinSatisiTalep;
}
