
import {Component, Mixins, Prop} from "vue-property-decorator";
import {BankaHacziParamsBankaRow} from "@/plugins/uyap-plugin/uyap/BankaHacziParams";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class HacizTalepBankaPicker extends Mixins(SimpleInputMixin) {
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;
  @Prop() maxDisplay!: number;
  @Prop() mevcutBankalar !: Array<{ bankaAdi: string, selected: boolean }>;
  @Prop() label!: string;
  @Prop() info!: string;

  items: Array<BankaHacziParamsBankaRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  created() {
    this.load();
  }

  tumunuSec() {
    this.$nextTick(() => {
      if (this.localValue?.length == this.items.length) {
        this.localValue = []
      } else {
        this.localValue = this.items.map(x => x.ilgiliKisiIlkKisiKurumId);
      }
      this.input();
    })
  }

  get icon() {
    if (this.localValue?.length == this.items.length)
      return 'mdi-close-box';
    if (this.localValue?.length > 0)
      return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  async load() {
    let bankalar = (await this.$uyap.BankaHacziParams().run({})).bankalar.sort((a, b) => (a.ilgili.localeCompare(b.ilgili)));
    if (this.mevcutBankalar) {
      bankalar.forEach((banka) => {
        banka.kisaAd = banka.ilgili
            .replace("TÜRK ANONİM ŞİRKETİ", "T.A.Ş.")
            .replace("ANONİM ŞİRKETİ", "A.Ş.")
            .replace("TÜRK ANONİM ORTAKLIĞI", "T.A.O.")
            .replace("TÜRKİYE CUMHURİYETİ", "T.C.")
            .replace(" GENEL MÜDÜRLÜĞÜ", "")
            .toLocaleLowerCase('tr')
      });
      let mevcutBankalar = this.mevcutBankalar.map(banka => banka.bankaAdi.toLocaleLowerCase('tr'));
      this.items = bankalar.filter(banka => mevcutBankalar.includes(banka.kisaAd));

    } else
      this.items = bankalar;
  }
}
