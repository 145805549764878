
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AracUzerindekiHacizSerhininKaldirilmasiTalep} from "@/interface/IUyapTalep";
import Plaka from "@/components/inputs/Plaka.vue";

@Component({
  components: {Plaka}
})
export default class AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: AracUzerindekiHacizSerhininKaldirilmasiTalep;
}
