
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})
export default class GenelTalepHesapKimeAitAlacakliRadio extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onHesapAitChange() {
    let name = this.localValue == 'A' ? 'Alacaklı' : (this.localValue == 'V' ? 'Vekil' : null);
    this.$emit('hesapKimeAitText', name);
    this.input();
  }
}
