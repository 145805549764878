
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HacizliMalinSatisiIcinTalimatYazilmasiTalep} from "@/interface/IUyapTalep";
import Dates from "@/components/inputs/Dates.vue";
import AvukatTalepMalTuruRadio from "@/components/radio/uyap/AvukatTalepMalTuruRadio.vue";
import DosyaKapatmaNedenleriPicker from "@/components/pickers/uyap/DosyaKapatmaNedenleriPicker.vue";
import UyapAdliyePicker from "@/components/pickers/uyap/UyapAdliyePicker.vue";

@Component({
  components: {UyapAdliyePicker, DosyaKapatmaNedenleriPicker, AvukatTalepMalTuruRadio, Dates}
})
export default class HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: HacizliMalinSatisiIcinTalimatYazilmasiTalep;
}
