
import {Vue, Component, Prop, Ref, Emit} from 'vue-property-decorator';
import {VForm} from "@/types";
import _ from "lodash";
import {AxiosResponse} from "axios";

@Component({})
export default class NewFormWrapper extends Vue {
  @Prop() action!: string;
  @Prop() value!: any;
  @Prop() disabled!: string | boolean;
  @Prop() hideCloseButton!: string | boolean;
  @Prop() hideSubmitButton!: string | boolean;
  @Prop() successOnValidate!: boolean | string;
  @Prop() closeButtonText!: string;
  @Prop() submitButtonText!: string;
  @Ref('form') readonly form!: VForm;

  valid: boolean = false;
  onFly: boolean = false;
  defaultValue!: any;

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get isCloseButtonHidden() {
    return this.hideCloseButton || this.hideCloseButton === "";
  }

  get isSubmitButtonHidden() {
    return this.hideSubmitButton || this.hideSubmitButton === "";
  }

  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  @Emit('onInput')
  @Emit('input')
  @Emit('value')
  input(value: any) {
    return value;
  }

  @Emit('onSuccess')
  success(value: any) {
    return value;
  }

  mounted() {
    this.setDefaultValue();
  }

  setDefaultValue() {
    this.defaultValue = _.cloneDeep(this.value);
  }

  setValue() {
    this.input(_.cloneDeep(this.defaultValue));
  }

  onClose() {
    this.resetForm();
    this.setValue();
    this.$emit("onClose");
  }

  onSuccess(data: any) {
    this.success(data);
    this.resetForm();
  }

  resetForm() {
    this.form.resetValidation()
  }

  onValidate() {
    this.form.validate();
    let isChildrenValid = this.validateChildFormWrappers();
    if (this.valid && isChildrenValid) {
      this.setDefaultValue();
      this.$emit("onValidate", this.valid);
    }
    return this.valid && isChildrenValid;
  }

  validateChildFormWrappers(component: Vue = this) {
    let valid = true
    let fn = (com: any) => {
      com.$children?.forEach((child: Vue) => {
        // @ts-ignore
        if (child.$options.name === "FormWrapper" || child.$options._componentTag == 'form-wrapper') {
          // @ts-ignore
          child.$refs.form?.validate();
          // @ts-ignore
          let isChildValid = child.valid !== undefined ? child.valid : true;
          valid = valid && isChildValid;
        }
        // @ts-ignore
        else if (child.$options._componentTag === "form-dialog") {
          return true;
        }
        return valid && fn(child);
      });
    }
    fn(component);
    return valid;
  }

  getRequestPromise() {
    if (this.value.id)
      return this.$http.put(`${this.action}/${this.value.id}`, this.value);
    else
      return this.$http.post(this.action, this.value)
  }

  onSubmit() {
    let valid=this.onValidate();
    if (valid) {
      if (this.isSuccessOnValidate) {
        this.onSuccess(this.value);
      } else if (this.action) {
        this.onFly = true;
        let request = this.getRequestPromise();
        request.then((response: AxiosResponse) => {
          this.onSuccess(response.data);
        }).finally(() => {
          this.onFly = false;
        });
      }
    }
  }

}
