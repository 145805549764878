import { render, staticRenderFns } from "./NewFormWrapper.vue?vue&type=template&id=d6ee8748&scoped=true"
import script from "./NewFormWrapper.vue?vue&type=script&lang=ts"
export * from "./NewFormWrapper.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6ee8748",
  null
  
)

export default component.exports