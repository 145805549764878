import {DosyaBorclu} from "@/plugins/uyap-plugin/uyap/DosyaBorclu";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {UyapDigerEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapDigerEvrakTuru";

export class VarsayilanGenel {
    acizBelgesiHazirlanmasi = {
        aciklama: ""
    }

    aracUzerindekiHacizSerhininKaldirilmasi = {
        plaka: ""
    }

    aracUzerindekiYakalamaSerhininKaldirilmasi = {
        plaka: ""
    }

    bankadanHacizliParaninIstenilmesi = {
        hacizTarihi: "",
        bankaList: [],
        aciklama: ""
    }

    borclutarafindanYapilanOdemelerinHesabaAktarilmasi = {
        hesapKimeAit: "",
        hesapKimeAitText: "",
        ibanNo: ""
    }

    dosyadaHacizliMalinSatisi = {
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        aciklama: ""
    }

    dosyadakiAvansinIadesi = {
        hesapKimeAit: "",
        hesapKimeAitText: "",
        ibanNo: ""
    }

    dosyadakiHacizlerinKaldirilmasi = {
        aciklama: ""
    }

    ibanGuncelleme = {
        hesapKimeAit: "",
        hesapKimeAitText: "",
        ibanNo: ""
    }

    dosyaninIslemdenKaldirilmasi = {
        kapanmaId: 0,
        kapanmaAdi: "",
        aciklama: ""
    }

    dosyaninYetkiliIcraDairesineGonderilmesi = {
        birimId: 0,
        birimAdi: "",
        aciklama: ""
    }

    hacizliMalinKiymetTakdiriIcinTalimatYazilmasi = {
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        birimId: 0,
        birimAdi: "",
        aciklama: ""
    }

    hacizliMalinSatisiIcinTalimatYazilmasi = {
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        birimId: 0,
        birimAdi: "",
        aciklama: ""
    }

    hacizliTasinirMallarinMuhafazaAltinaAlinmasi = {
        hacizTarihi: ""
    }

    haricenTahsilatBildirimi = {
        aciklama: "",
        tahsilatTarihi: "",
        tahsilatTutari: 0
    }

    iik121MaddeyeGoreYetkiVerilmesi = {
        aciklama: ""
    }

    iik150cSerhiEklenmesi = {
        aciklama: ""
    }

    kiymetTakdirininYapilmasi = {
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        aciklama: ""
    }

    maasUzerindekiHacizlerinKaldirilmesi = {
        calismaDurumu: 0,
        aciklama: ""
    }

    muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi = {
        muhafazaTarihi: "",
        yeddiemin: "",
        aciklama: ""
    }

    rehinAcigiBelgesiHazirlanmasi = {
        belgeTip: 0,
        aciklama: "",
        belgeTipAciklama: ""
    }

    rehininParayaCevrilmesiSerhi = {
        aciklama: ""
    }

    tasinmazHaczininKaldirilmasi = {
        hacizTarihi: "",
        aciklama: ""
    }

    yuzuncuMaddeyeYararBilgiIstenilmesi = {
        malTip: 0,
        malTipAciklama: "",
        aciklama: ""
    }

    setVarsayilanTalepler(borclu: DosyaBorclu, talepler: Array<AvukatTalepListCevapRow>): DosyaBorclu {
        let talepKodlari = talepler.map(talep => talep.talepKodu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.aciz_belgesi_hazirlanmasi))
            this.setAcizBelgesiHazirlanmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.arac_uzerindeki_haciz_serhinin_kaldirilmasi))
            this.setAracUzerindekiHacizSerhininKaldirilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.arac_uzerindeki_yakalama_serhinin_kaldirilmasi))
            this.setAracUzerindekiYakalamaSerhininKaldirilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.bankadan_hacizli_paranin_istenilmesi))
            this.setBankadanHacizliParaninIstenilmesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.borclu_tarafindan_yapilan_odemelerin_hesaba_aktarilmasi))
            this.setBorclutarafindanYapilanOdemelerinHesabaAktarilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyada_hacizli_malin_satisi))
            this.setDosyadaHacizliMalinSatisi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyadaki_avansin_iadesi))
            this.setDosyadakiAvansinIadesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyadaki_hacizlerin_kaldirilmasi))
            this.setDosyadakiHacizlerinKaldirilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyadaki_IBAN_bilgisinin_guncellenmesi))
            this.setIbanGuncelleme(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyanin_islemden_kaldirilmasi))
            this.setDosyaninIslemdenKaldirilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.dosyanin_yetkili_icra_dairesine_gonderilmesi))
            this.setDosyaninYetkiliIcraDairesineGonderilmesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.hacizli_malin_kiymet_takdiri_icin_talimat_yazilmasi))
            this.setHacizliMalinKiymetTakdiriIcinTalimatYazilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.hacizli_malin_satisi_icin_talimat_yazilmasi))
            this.setHacizliMalinSatisiIcinTalimatYazilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.hacizli_tasinir_mallarin_muhafaza_altina_alinmasi))
            this.setHacizliTasinirMallarinMuhafazaAltinaAlinmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.haricen_tahsilat_bildirimi))
            this.setHaricenTahsilatBildirimi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.iIK121_maddeye_gore_yetki_verilmesi))
            this.setIik121MaddeyeGoreYetkiVerilmesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.iIK150C_serhi_eklenmesi))
            this.setIik150cSerhiEklenmesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.kiymet_takdirinin_yapilmasi))
            this.setKiymetTakdirininYapilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.maas_uzerindeki_hacizlerin_kaldirilmasi))
            this.setMaasUzerindekiHacizlerinKaldirilmesi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.muhafaza_altindaki_mallarin_haciz_baki_kalarak_yeddiemin_degisikligi))
            this.setMuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.rehin_acigi_belgesi_hazirlanmasi))
            this.setRehinAcigiBelgesiHazirlanmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.rehinin_paraya_cevrilmesi_serhi))
            this.setRehininParayaCevrilmesiSerhi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.tasinmaz_haczinin_kaldirilmasi))
            this.setTasinmazHaczininKaldirilmasi(borclu);
        if (talepKodlari.includes(UyapDigerEvrakTuru.yuzuncu_maddeye_yarar_bilgi_istenilmesi))
            this.setYuzuncuMaddeyeYararBilgiIstenilmesi(borclu);
        return borclu;
    }

    setAcizBelgesiHazirlanmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.acizBelgesiHazirlanmasiParams = {
            aciklama: this.acizBelgesiHazirlanmasi.aciklama
        }
    }

    setAracUzerindekiHacizSerhininKaldirilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.aracUzerindekiHacizSerhininKaldirilmasiParams = {
            plaka: this.aracUzerindekiHacizSerhininKaldirilmasi.plaka
        }
    }

    setAracUzerindekiYakalamaSerhininKaldirilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.aracUzerindekiYakalamaSerhininKaldirilmasiParams = {
            plaka: this.aracUzerindekiYakalamaSerhininKaldirilmasi.plaka
        }
    }

    setBankadanHacizliParaninIstenilmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.bankadanHacizliParaninIstenilmesiParams = {
            aciklama: this.bankadanHacizliParaninIstenilmesi.aciklama,
            bankaList: this.bankadanHacizliParaninIstenilmesi.bankaList,
            hacizTarihi: this.bankadanHacizliParaninIstenilmesi.hacizTarihi
        }
    }

    setBorclutarafindanYapilanOdemelerinHesabaAktarilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.borclutarafindanYapilanOdemelerinHesabaAktarilmasiParams = {
            // @ts-ignore
            hesapKimeAit: this.borclutarafindanYapilanOdemelerinHesabaAktarilmasi.hesapKimeAitText,
            ibanNo: this.borclutarafindanYapilanOdemelerinHesabaAktarilmasi.ibanNo
        }
    }

    setDosyadaHacizliMalinSatisi(borclu: DosyaBorclu) {
        borclu.digerTalep.dosyadaHacizliMalinSatisiParams = {
            aciklama: this.dosyadaHacizliMalinSatisi.aciklama,
            hacizTarihi: this.dosyadaHacizliMalinSatisi.hacizTarihi,
            malTip: this.dosyadaHacizliMalinSatisi.malTip,
            malTipAciklama: this.dosyadaHacizliMalinSatisi.malTipAciklama
        }
    }

    setDosyadakiAvansinIadesi(borclu: DosyaBorclu) {
        borclu.digerTalep.dosyadakiAvansinIadesiParams = {
            // @ts-ignore
            hesapKimeAit: this.dosyadakiAvansinIadesi.hesapKimeAitText,
            ibanNo: this.dosyadakiAvansinIadesi.ibanNo
        }
    }

    setDosyadakiHacizlerinKaldirilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.dosyadakiHacizlerinKaldirilmasiParams = {
            aciklama: this.dosyadakiHacizlerinKaldirilmasi.aciklama
        }
    }

    setIbanGuncelleme(borclu: DosyaBorclu) {
        borclu.digerTalep.ibanGuncellemeParams = {
            ibanNo: this.ibanGuncelleme.ibanNo,
            ibanSecimi: this.ibanGuncelleme.hesapKimeAitText
        }
    }

    setDosyaninIslemdenKaldirilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.dosyaninIslemdenKaldirilmasiParams = {
            aciklama: this.dosyaninIslemdenKaldirilmasi.aciklama,
            kapanmaAdi: this.dosyaninIslemdenKaldirilmasi.kapanmaAdi,
            kapanmaId: this.dosyaninIslemdenKaldirilmasi.kapanmaId
        }
    }

    setDosyaninYetkiliIcraDairesineGonderilmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.dosyaninYetkiliIcraDairesineGonderilmesiParams = {
            aciklama: this.dosyaninYetkiliIcraDairesineGonderilmesi.aciklama,
            birimAdi: this.dosyaninYetkiliIcraDairesineGonderilmesi.birimAdi,
            birimId: this.dosyaninYetkiliIcraDairesineGonderilmesi.birimId
        }
    }

    setHacizliMalinKiymetTakdiriIcinTalimatYazilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.hacizliMalinKiymetTakdiriIcinTalimatYazilmasiParams = {
            aciklama: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.aciklama,
            birimAdi: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.birimAdi,
            birimId: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.birimId,
            hacizTarihi: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.hacizTarihi,
            malTip: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.malTip,
            malTipAciklama: this.hacizliMalinKiymetTakdiriIcinTalimatYazilmasi.malTipAciklama
        }
    }

    setHacizliMalinSatisiIcinTalimatYazilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.hacizliMalinSatisiIcinTalimatYazilmasiParams = {
            aciklama: this.hacizliMalinSatisiIcinTalimatYazilmasi.aciklama,
            birimAdi: this.hacizliMalinSatisiIcinTalimatYazilmasi.birimAdi,
            birimId: this.hacizliMalinSatisiIcinTalimatYazilmasi.birimId,
            hacizTarihi: this.hacizliMalinSatisiIcinTalimatYazilmasi.hacizTarihi,
            malTip: this.hacizliMalinSatisiIcinTalimatYazilmasi.malTip,
            malTipAciklama: this.hacizliMalinSatisiIcinTalimatYazilmasi.malTipAciklama
        }
    }

    setHacizliTasinirMallarinMuhafazaAltinaAlinmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.hacizliTasinirMallarinMuhafazaAltinaAlinmasiParams = {
            hacizTarihi: this.hacizliTasinirMallarinMuhafazaAltinaAlinmasi.hacizTarihi
        }
    }

    setHaricenTahsilatBildirimi(borclu: DosyaBorclu) {
        borclu.digerTalep.haricenTahsilatBildirimiParams = {
            aciklama: this.haricenTahsilatBildirimi.aciklama,
            tahsilatTarihi: this.haricenTahsilatBildirimi.tahsilatTarihi,
            tahsilatTutari: this.haricenTahsilatBildirimi.tahsilatTutari
        }
    }

    setIik121MaddeyeGoreYetkiVerilmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.iik121MaddeyeGoreYetkiVerilmesiParams = {
            aciklama: this.iik121MaddeyeGoreYetkiVerilmesi.aciklama
        }
    }

    setIik150cSerhiEklenmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.iik150cSerhiEklenmesiParams = {
            aciklama: this.iik150cSerhiEklenmesi.aciklama
        }
    }

    setKiymetTakdirininYapilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.kiymetTakdirininYapilmasiParams = {
            aciklama: this.kiymetTakdirininYapilmasi.aciklama,
            hacizTarihi: this.kiymetTakdirininYapilmasi.hacizTarihi,
            malTip: this.kiymetTakdirininYapilmasi.malTip,
            malTipAciklama: this.kiymetTakdirininYapilmasi.malTipAciklama
        }
    }

    setMaasUzerindekiHacizlerinKaldirilmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.maasUzerindekiHacizlerinKaldirilmesiParams = {
            aciklama: this.maasUzerindekiHacizlerinKaldirilmesi.aciklama,
            calismaDurumu: this.maasUzerindekiHacizlerinKaldirilmesi.calismaDurumu
        }
    }

    setMuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi(borclu: DosyaBorclu) {
        borclu.digerTalep.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParams = {
            aciklama: this.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi.aciklama,
            muhafazaTarihi: this.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi.muhafazaTarihi,
            yeddiemin: this.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi.yeddiemin
        }
    }

    setRehinAcigiBelgesiHazirlanmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.rehinAcigiBelgesiHazirlanmasiParams = {
            aciklama: this.rehinAcigiBelgesiHazirlanmasi.aciklama,
            belgeTip: this.rehinAcigiBelgesiHazirlanmasi.belgeTip,
            belgeTipAciklama: this.rehinAcigiBelgesiHazirlanmasi.belgeTipAciklama
        }
    }

    setRehininParayaCevrilmesiSerhi(borclu: DosyaBorclu) {
        borclu.digerTalep.rehininParayaCevrilmesiSerhiParams = {
            aciklama: this.rehininParayaCevrilmesiSerhi.aciklama
        }
    }

    setTasinmazHaczininKaldirilmasi(borclu: DosyaBorclu) {
        borclu.digerTalep.tasinmazHaczininKaldirilmasiParams = {
            aciklama: this.tasinmazHaczininKaldirilmasi.aciklama,
            hacizTarihi: this.tasinmazHaczininKaldirilmasi.hacizTarihi
        }
    }

    setYuzuncuMaddeyeYararBilgiIstenilmesi(borclu: DosyaBorclu) {
        borclu.digerTalep.yuzuncuMaddeyeYararBilgiIstenilmesiParams = {
            aciklama: this.yuzuncuMaddeyeYararBilgiIstenilmesi.aciklama,
            malTip: this.yuzuncuMaddeyeYararBilgiIstenilmesi.malTip,
            malTipAciklama: this.yuzuncuMaddeyeYararBilgiIstenilmesi.malTipAciklama
        }
    }
}