
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {BakiyeBorcMuhtirasininTebligeCikartilmasiTalep} from "@/interface/IUyapTalep";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";

@Component({
  components: {AvukatTalepAdresTuruPicker, AvukatTalepTebligatTuruPicker}
})
export default class BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() tckn!: string;
  @Prop() vergiNo!: string;
  @Prop() kisiMi!: boolean;

  localValue!: BakiyeBorcMuhtirasininTebligeCikartilmasiTalep;
  sorguProgress:boolean=false;


  async adresSorgula() {
    this.sorguProgress = true;

    // mernis 1 mersis 2
    if (this.localValue.adresTuru == '1' && this.tckn) {
      let mernisRes = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: this.tckn});
      this.localValue.adres = mernisRes ? 'VAR' : 'YOK';
    } else if (this.localValue.adresTuru == '2' && this.vergiNo) {
      // TODO: Talep uyaptan kontrol edilecek
      let mersisRes = await this.$uyap.GetKurumMersisAdresi().run({mersisNo:"",vergiNo: this.vergiNo});
      this.localValue.adres = !!mersisRes ? 'VAR' : 'YOK';
    }
    setTimeout(() => {
      this.sorguProgress=false
    }, 2000)
  }
}
