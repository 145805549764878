
import {Component, Mixins, Prop} from "vue-property-decorator";
import {IAvukatTalepAdresTuru} from "@/plugins/uyap-plugin/uyap/GetPortalAvukatTalepAdresTuruList";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class AvukatTalepAdresTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;
  @Prop() kisiMi!: boolean | undefined;

  items: Array<IAvukatTalepAdresTuru> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onAdresTuruChange(v: IAvukatTalepAdresTuru) {
    this.$emit('adresTuruAciklama', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    let items = (await this.$uyap.GetPortalAvukatTalepAdresTuruList().run({}));
    items.forEach((item, index) => {
      if (this.kisiMi === true && item.value == 2) {
        this.items.slice(index, 1)
      }
      if (this.kisiMi === false && item.value == 1) {
        this.items.slice(index, 1)
      }
    })
    this.items = items.filter((item) => {
      if (this.kisiMi === true)
        return item.value != 2;
      else if (this.kisiMi === false)
        return item.value != 1;
      else
        return true;
    });
  }
}
