
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {AdliyelerRow} from "@/plugins/uyap-plugin/uyap/Adliyeler";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlEntity} from "@/entity/IlEntity";

@Component({
  components: {SehirPicker}
})

export default class UyapAdliyePicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  il: IlEntity|null = null;
  items: Array<AdliyelerRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  @Watch('il')
  onIlKoduChange() {
    this.load();
  }

  onBirimChange(v: AdliyelerRow) {
    this.$emit('birimAdi', (this.items.find(item => item.adliyeBirimID == this.localValue))?.adliyeIsmi);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    if (this.il)
      this.items = await this.$uyap.Adliyeler().run({ilKodu: this.il.kod});
  }
}
