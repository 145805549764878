
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {DosyadakiAvansinIadesiTalep} from "@/interface/IUyapTalep";
import GenelTalepHesapKimeAitAlacakliRadio from "@/components/radio/uyap/GenelTalepHesapKimeAitAlacakliRadio.vue";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";

@Component({
  components: {IbanNumarasi, GenelTalepHesapKimeAitAlacakliRadio}
})
export default class DosyadakiAvansinIadesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: DosyadakiAvansinIadesiTalep;
}
