
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {DosyadakiHacizlerinKaldirilmasiTalep} from "@/interface/IUyapTalep";

@Component({
  components: {}
})
export default class DosyadakiHacizlerinKaldirilmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: DosyadakiHacizlerinKaldirilmasiTalep;
}
