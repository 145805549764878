

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {DosyaKapatmaNedenleriCevap, IDosyaKapatmaNedeni} from "@/plugins/uyap-plugin/uyap/DosyaKapatmaNedenleri";

@Component({
  components: {}
})

export default class DosyaKapatmaNedenleriPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  items: DosyaKapatmaNedenleriCevap = [];

  onKapatmaNedeniChange(v: IDosyaKapatmaNedeni){
    this.$emit('kapanmaAdi', (this.items.find(item => item.value == this.localValue))?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.DosyaKapatmaNedenleri().run({dosyaTurKod: 35}));
  }
}
