
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {RehinAcigiBelgesiHazirlanmasiTalep} from "@/interface/IUyapTalep";

@Component({
  components: {}
})
export default class RehinAcigiBelgesiHazirlanmasiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: RehinAcigiBelgesiHazirlanmasiTalep;

  onBelgeTipiChange() {
    if (this.localValue.belgeTip == 0)
      this.localValue.belgeTipAciklama = 'Geçici Rehin Açığı';
    else if (this.localValue.belgeTip == 1)
      this.localValue.belgeTipAciklama = 'Kesin Rehin Açığı';
    this.input();
  }
}
