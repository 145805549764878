
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {BankadanHacizliParaninIstenilmesiTalep} from "@/interface/IUyapTalep";
import Dates from "@/components/inputs/Dates.vue";
import HacizTalepBankaPicker from "@/components/pickers/uyap/HacizTalepBankaPicker.vue";

@Component({
  components: {HacizTalepBankaPicker, Dates}
})
export default class BankadanHacizliParaninIstenilmesiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: BankadanHacizliParaninIstenilmesiTalep;
}
