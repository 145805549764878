
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {HaricenTahsilatBildirimiTalep} from "@/interface/IUyapTalep";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";

@Component({
  components: {Dates, Tutar}
})
export default class HaricenTahsilatBildirimiTalepEvrakForm extends Mixins(ObjectInputMixin) {
  localValue!: HaricenTahsilatBildirimiTalep;
}
